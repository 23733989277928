//common values
@site-min-width: 350px;
@site-max-width: 920px;
@margin: 20px;

//font style
@font-family: Arial, Helvetica, sans-serif;
@font-color: #494949;
@font-color-disabled: #6f6f6f;
@font-size: 14px;
@line-height: 25px;
@mots-bleus: #0000ff;

//couleurs
@background: #e5e5e5;
@gris-menu : #f5f5f5;
@gris-border: #C7C7C7;
@gris-background: #EEEEEE;
@bleu: #5C8BAE;
@bleu-hover: #437092;
@bleu-border: #A4B9CF;
@bleu-background: #e6f3f7;
@bleu-form: #007ac2;
@orange: #D68E07;
@orange-border: #DDB468;
@orange-background: #f9ebd1;
@rouge: #F56046;
@rouge-border: #F07667;
@rouge-background: #f4e1e1;
@rouge-impression: #dd3b3b;
@vert: #49a206;
@vert-background: #ebf7e6;
@jaune: #F3C647;

//couleurs modules
@topbar_module_bleu: #6babc5;
@topbar_menu_bleu: #5f9bb4;
@menu_selected_bleu: #4a8aa4;
@topbar_module_orange: #e28955;
@topbar_menu_orange: #d87338;
@menu_selected_orange: #cc5815;
@topbar_module_vert: #6bc576;
@topbar_menu_vert: #5baf65;
@menu_selected_vert: #539c5c;
@topbar_module_rouge: #ec6b6b;
@topbar_menu_rouge: #b55e5e;
@menu_selected_rouge: #a54f4f;
@topbar_module_violet: #6b76c5;
@topbar_menu_violet: #5f69b4;
@menu_selected_violet: #4a59a4;
@topbar_module_jaune: #ffce3b;
@topbar_menu_jaune: #eab200;
@menu_selected_jaune: #ce9f0c;

//police dyslexie
@font-face {
	font-family: 'Open-dyslexic';
	src: url('../fonts/open_dyslexic/OpenDyslexic-Regular.eot');
	src: url('../fonts/open_dyslexic/OpenDyslexic-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/open_dyslexic/OpenDyslexic-Regular.woff') format('woff'),
		url('../fonts/open_dyslexic/OpenDyslexic-Regular.ttf') format('truetype');
}

//police font-awesome
@font-face {
	font-family: 'FontAwesome';
	src:  url('../fonts/font_awesome/FontAwesome.eot');
	src:  url('../fonts/font_awesome/FontAwesome.eot#iefix') format('embedded-opentype'),
		url('../fonts/font_awesome/FontAwesome.woff') format('woff'),
		url('../fonts/font_awesome/FontAwesome.ttf') format('truetype'),
		url('../fonts/font_awesome/FontAwesome.svg#FontAwesome') format('svg');
	font-weight: normal;
	font-style: normal;
}

//police Roboto
@font-face {
	font-family: 'Roboto';
	src:  url('../fonts/roboto/Roboto-Regular.ttf');
	font-weight: normal;
	font-style: normal;
}

//opacite tous navigateurs
.opacity (@value) {
	@filter: @value * 100;
	filter: ~"alpha(opacity = @{filter})";
	opacity: @value;
}

//ombrage
.shadow {
	box-shadow: 0 0 5px 0 @gris-border;
}

//texte highligthed
.highlighted {
	color: @mots-bleus;
}

//texte gras bleu
.bleu {
	color: @bleu!important;
	font-weight: bold;
}

//texte gras orange
.orange {
	color: @orange!important;
	font-weight: bold;
}

//texte gras vert
.vert {
	color: @vert!important;
	font-weight: bold;
}

//texte gras rouge
.rouge {
	color: @rouge!important;
	font-weight: bold;
}

//texte gras jaune
.jaune {
	color: @jaune!important;
	font-weight: bold;
}

//texte gras gris
.gris {
	color: grey!important;
}

//curseur main
.pointer {
	cursor: pointer;
}

//empeche le select
.disable_select {
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;
}

html, body {
	margin: 0;
	padding: 0;
	height: 100%;
	background: @background;
	font: normal @font-size @font-family;
	color: @font-color;
	-webkit-font-smoothing: antialiased;
	-webkit-touch-callout: none;
}

a {
	.pointer;
	color: @bleu;
	text-decoration: underline;
	font-weight: normal!important;
	&:hover {
		color: @bleu-hover;
	}
}

h1 {
	font-size: 16px;
	margin: 0;
	font-weight: normal;
}

h2 {
	font-size: 18px;
	font-family: @font-family;
	color: @font-color;
	font-weight: normal;
	margin: 0;
}

.label {
	font-weight: bold;
	margin: 3px 0 3px 0;
}

//message d'erreur des formulaires
.form_errors {
	display: none;
	text-align: center;
	width: 100%;
	margin-top: 10px;
}

//message d'erreur en bas de l'interface
.errors_message {
	z-index: 50;
	background-color: @orange-background;
	padding: 20px 30px 20px 30px;
	font-weight: bold;
	text-align: center;
	position: fixed;
	left: 50%;
	transform: translate(-50%, 0);
	display: inline-block;
	border-radius: 10px;
	bottom: 60px;
	box-shadow: 0px 0px 5px 0px @orange-border;
}

//hover au passage de la souris
.bouton_hover {
	display: inline-block;
	.pointer;
	.opacity(1);
	&:hover {
		.opacity(0.8);
	}
}

//disable un bouton image
.bouton_disabled {
	.opacity(0.5);
	cursor: not-allowed;
}

//coupe le contenu qui est trop long
.ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
}

//style des boutons
button {
	border: 1px solid @gris-border;
	background-color: white;
	padding: 6px 8px 7px 8px;
	cursor: pointer;
	color: @font-color;
	font-size: @font-size;
	border-radius: 10px;
	&:hover {
		border: 1px solid @bleu;
	}
	&:active {
		box-shadow: inset 1px 1px 8px @gris-border;
	}
}

//bouton désactivé
button:disabled, button[disabled] {
	border: 1px solid @gris-border;
	background-color: @gris-menu;
	color: @gris-border;
	cursor: not-allowed;
	&:hover {
		background:@gris-menu;
		border: 1px solid @gris-border;
		color: @gris-border;
	}
}

//style des checkboxs
input[type=checkbox] {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	height: 20px;
	width: 20px;
	background: white;
	border: 1px solid @gris-border;
	border-radius: 6px;
	vertical-align: middle;
	&:hover {
		.pointer;
		border: 1px solid @bleu;
	}
	&:checked {
		background-color: @bleu-form;
		border: 1px solid @bleu;
		&:before {
			display: inline-block;
			font-family: FontAwesome;
			content: '\f00c';
			color: white;
			font-size: @font-size;
			margin: 0 0 0 2px;
		}
	}
}

//style des select
select {
	background: white;
	border-radius: 10px;
	color: @font-color;
	font-family: @font-family;
	font-size: @font-size;
	padding: 6px;
	border: 1px solid @gris-border;
	&:hover {
		.pointer;
		border: 1px solid @bleu;
	}
	&:disabled {
		background: @gris-menu;
		border: 1px solid @gris-border!important;
		.opacity(0.65);
		cursor: default;
	}
}

//style des champs texte
input[type=text], input[type=password], input[type=date], input[type=time], input[type=number], input[type=email] {
	color: @font-color;
	font-family: @font-family;
	font-size: @font-size;
	padding: 8px;
	border: 1px solid @gris-border;
	border-radius: 10px;
	&:hover {
		border: 1px solid @bleu;
	}
}

//champ invalide
.input_invalid {
	border: 1px solid @rouge-border!important;
}

//champ selectize
.selectize-input {
	font: normal @font-size @font-family!important;
	color: @font-color!important;
	border-radius: 10px!important;
}

//style des boutons radio
input[type=radio] {
	height: 17px;
	width: 17px;
	.pointer;
	vertical-align: middle;
}

//style des textarea
textarea {
	border-radius: 10px;
	border: 1px solid @gris-border;
}

//fieldsets
fieldset {
	border: 1px dashed @gris-border;
	border-radius: 10px;
	text-align: left;
}

/* --------------------------------------------
	CLASSES DES TOOLTIPS
-------------------------------------------- */

.tooltip {
	background-color: rgba(0, 0, 0, 0.7);
	color: white;
	padding: 5px;
	border-radius: 5px;
	position: absolute;
	z-index: 50;
	white-space: nowrap;
}

.tooltip-fleche {
	position: absolute;
	z-index: 50;
	width: 8px;
	height: 8px;
	background-color: rgba(0, 0, 0, 0.7);
	clip-path: polygon(0 0, 100% 0, 50% 100%);
}

/* --------------------------------------------
	CLASSES DES TITLEPANES
-------------------------------------------- */

.title-pane {
	.title-bar {
		.pointer;
		padding: 10px;
		background-color: @gris-menu;
		border-bottom: 1px solid @gris-border;
		&:hover {
			background-color: @gris-background;
		}
	}
	.content-pane {
		padding: 10px;
		border-bottom: 1px solid @gris-border;
	}
}

/* --------------------------------------------
	CLASSES DES DIALOGS
-------------------------------------------- */

/* Style pour la fenêtre dialog */
.dialog {
	display: none;
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
}

//titre du dialog
.dialog-title {
	display: flex;
	background: @gris-menu!important;
	line-height: 35px;
	border-bottom: 3px double @gris-border;
	padding: 8px 12px;
}

//bouton ferme
.dialog-close {
	.pointer;
	margin-left: auto;
	.icone("\f00d", @rouge, 20px);
}

//contenu du dialog
.dialog-content {
	background-color: white;
	margin: auto;
	border: 1px solid @gris-border;
	width: fit-content;
	max-width: 700px;
	min-width: 300px;
	border-radius: 10px;
	overflow: hidden;
}
.dialog-message {
	text-align: center;
	padding: 20px;
	line-height: 25px;
	max-height: 400px;
	overflow: auto;
}

//boutons des dialogs
.dialog-boutons {
	border-top: 1px dashed @gris-border;
	background: @gris-menu;
	padding: 10px;
	text-align: center;
}

/* --------------------------------------------
	DIVS DE CONTENU
-------------------------------------------- */

//div container des div_bottom
.div_bottom_container {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin: 0;
	padding: 0;
	width: 100%;
	margin-bottom: @margin;
	border-radius: 10px;
	box-shadow: 0px 0px 5px 0px @gris-border;
	background-color: white;
	overflow: auto;
}

//div toute seule
.div_bottom_container_alone {
	.div_bottom_container;
	background-color: @gris-menu;
	padding: 10px;
	width: fit-content;
}

//div de titre
.div_titre {
	position: relative;
	display: flex;
	gap: 5px;
	align-items: center;
	padding: 15px 10px 15px 10px;
	text-align: left;
	border-bottom: 3px double @gris-border;
	background-color: @gris-menu;
	line-height: 30px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	&>div {
		display: inline-block;
		vertical-align: middle;
	}
	b {
		.ellipsis;
	}
	.div_titre_icone {
		flex: 0 0 2.3em;
		margin-right: 8px;
	}
	.div_titre_boutons {
		display: flex;
		flex-wrap: wrap;
		gap: 5px;
		margin-left: auto;
		.icone-refresh {
			margin-top: 2px;
		}
		input[type=radio] {
			margin-top: 6px;
		}
	}
	.div_titre_input {
		float: right;
		div {
			display: block!important;
		}
	}
}

//div de titre avec le texte aligné au centre
.div_titre_center {
	.div_titre;
	justify-content: center;
	text-align: center;
	a {
		font-weight: bold!important;
	}
}

//div de titre seule
.div_titre_alone {
	.div_titre;
	justify-content: left!important;
	border-bottom: 0!important;
}

//div en dessous de la div titre
.div_bottom {
	overflow: auto;
	line-height: @line-height;
	background-color: white;
	padding: 20px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

//div en dessous de la div titre
.div_bottom_tableau {
	.div_bottom;
	padding: 0px!important;
}

//lien avec grosse icone
.lien_grosse_icone {
	.pointer;
	display: inline-block;
	margin: 10px 15px 0 15px;
	div {
		display: inline-block;
		vertical-align: middle;
		&:last-child {
			text-decoration: underline;
		}
	}
	&:hover {
		color: @bleu;
	}
}

//menu d'une div_bottom
.div_bottom_menu {
	background-color: @gris-menu;
	border-top: 1px dashed @gris-border;
	padding: 0 5px 12px 5px;
	.bouton_menu {
		display: inline-block;
		margin-top: 10px;
	}
}

/* --------------------------------------------
	TABLEAUX
-------------------------------------------- */

//tableau de formulaire
.tableau_formulaire {
	margin: auto;
	min-width: 300px;
	td {
		padding: 3px;
	}
	input[type=text], input[type=password], input[type=email], textarea {
		width: 95%;
	}
}

//tableau normal
.tableaux {
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;
	text-align: center;
	line-height: 20px;
	thead {
		tr {
			height: 28px;
		}
		.header_array {
			.pointer;
			&:after {
				content: "\f0dc";
				font-family: FontAwesome;
				float: right;
				margin-top: 2px;
			}
		}
		.headerSortUp {
			background-image: none!important;
			&:after {
				content: "\f0de";
				font-family: FontAwesome;
				float: right;
			}
		}
		.headerSortDown {
			background-image: none!important;
			&:after {
				content: "\f0dd";
				font-family: FontAwesome;
				float: right;
			}
		}
		th {
			font-weight: normal;
			padding: 5px;
			background-color: @gris-menu;
			border-right: 1px dashed @gris-border;
			border-bottom: 1px dashed @gris-border;
			&:first-child {
				border-left: 0!important;
			}
			&:last-child {
				border-right: 0!important;
			}
		}
	}
	> tbody {
		> tr {
			height: 45px;
			background-color: white;
			&:hover {
				background-color: @gris-menu;
			}
			&:last-child > td {
				border-bottom: 0!important;
			}
		}
		td {
			max-width: 0;
			border: 1px solid @gris-border;
			border-style: none solid solid none;
			padding: 5px;
			vertical-align: middle;
			&:last-child {
				border-right: 0!important;
			}
		}
		.td_dashed {
			border-right: 1px dashed @gris-border;
			border-left: 0px;
		}
	}
	.th_graph {
		background-color: @gris-menu;
		width: 20%;
		.ellipsis;
		white-space: nowrap;
		border-right: 3px double @gris-border;
	}
	.td_graph {
		padding: 0!important;
		width: 8%;
		height: 45px;
		border-right: 1px dashed @gris-border;
	}
}

//tableau sans effet hover
.tableau_sans_hover {
	tr:hover {
		background-color: white!important;
	}
}

//border du tableau
.tableau_border {
	border: 1px solid @gris-border;
	border-radius: 10px;
	overflow: hidden;
}

//premiere ligne reste fixe
.tableau_sticky {
	thead th {
		position: sticky;
		top: 0;
		z-index: 1;
	}
}

/* --------------------------------------------
	AVANT LE HEADER
-------------------------------------------- */

//masque blanc pendant le chargement
#preloader {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 999;
	background-color: white;
}

//conteneur du site
#container {
	min-height: 100%;
	min-width: @site-min-width;
	margin-bottom: -40px;
	&:after {
		content: "";
		display: block;
		height: 40px;
	}
}

//couleurs de l'interface en fonction du module
.couleurs_inferface(@couleur_topbar, @couleur_menu, @couleur_menu_selected){
	.topbar, .topbar_acces_rapide, .topbar_content {
		background-color: @couleur_topbar;
	}
	.menu {
		overflow: auto;
		background-color: @couleur_menu;
		.menu_item:hover {
			background-color: @couleur_menu_selected!important;
		}
		.menu_selected {
			background-color: @couleur_menu_selected!important;
		}

		box-shadow: 0px 2px 3px 0px @gris-border;
	}
	.marqueur_module {
		background-color: @couleur_menu;
		width: 8px;
		min-width: 8px;
		height: 25px;
		border-radius: 10px;
	}
	.sous_menu {
		background-color: @couleur_menu;
		border: 1px solid @couleur_menu_selected;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		overflow: hidden;
		.sous_menu_item:hover {
			background-color: @couleur_menu_selected!important;
		}
	}
	.timer_svg {
		fill: @couleur_menu_selected;
	}
	.footer {
		background-color: @couleur_menu;
	}
	#avancement_entrainement {
		background-color: @couleur_menu;
	}
}

//module implicite
.interface_bleu {
	.couleurs_inferface(@topbar_module_bleu, @topbar_menu_bleu, @menu_selected_bleu);
}
.texte_module_bleu {
	color: @topbar_module_bleu;
}
.menu_module_bleu {
	background-color: @topbar_menu_bleu;
}

//module vocabulaire
.interface_orange {
	.couleurs_inferface(@topbar_module_orange, @topbar_menu_orange, @menu_selected_orange);
}
.texte_module_orange {
	color: @topbar_module_orange;
}
.menu_module_orange {
	background-color: @topbar_menu_orange;
}

//module grammaire
.interface_rouge {
	.couleurs_inferface(@topbar_module_rouge, @topbar_menu_rouge, @menu_selected_rouge);
}
.texte_module_rouge {
	color: @topbar_module_rouge;
}
.menu_module_rouge {
	background-color: @topbar_menu_rouge;
}

//module math
.interface_vert {
	.couleurs_inferface(@topbar_module_vert, @topbar_menu_vert, @menu_selected_vert);
}
.texte_module_vert {
	color: @topbar_module_vert;
}
.menu_module_vert {
	background-color: @topbar_menu_vert;
}

//module compréhension
.interface_violet {
	.couleurs_inferface(@topbar_module_violet, @topbar_menu_violet, @menu_selected_violet);
}
.texte_module_violet {
	color: @topbar_module_violet;
}
.menu_module_violet {
	background-color: @topbar_menu_violet;
}

//module orthographe gammaire
.interface_jaune {
	.couleurs_inferface(@topbar_module_jaune, @topbar_menu_jaune, @menu_selected_jaune);
}
.texte_module_jaune {
	color: @topbar_module_jaune;
}
.menu_module_jaune {
	background-color: @topbar_menu_jaune;
}

/* --------------------------------------------
	HEADER
-------------------------------------------- */

//barre avec le logo
.topbar {
	width: 100%;
	left: 0;
	right: 0;

	//largeur du contenu
	.topbar_content {
		max-width: @site-max-width;
		margin: 0 auto;
		padding: 0 10px 0 10px;
		min-height: 120px;
		height: auto;
		overflow: hidden;
		display: flex;
		align-items: center;

		//menu d'acces rapide en haut à droite
		.topbar_acces_rapide {
			margin-left: auto;
			order: 2;

			//bouton acces rapide
			.topbar_bouton {
				min-height: 26px;
				.bouton_hover;
				padding: 4px;
				background-color: white;
				border-radius: 10px;
				margin: 2px;
				vertical-align: middle;
			}

			//login 
			.topbar_login {
				cursor: default;
				padding: 5px 10px 5px 10px!important;
				div {
					vertical-align: middle;
					display: inline-block;
					margin-left: 5px;
				}
				&:hover {
					.opacity(1);
				}
			}
		}
	}
}

//menu sous le header
.menu {
	position: relative;
	width: 100%;
	z-index: 10;
	font-size: 16px;
	color: white;

	//largeur du contenu
	.menu_content {
		max-width: @site-max-width;
		margin: auto;
		padding: 8px;

		//item du menu
		.menu_item {
			display: inline-block;
			vertical-align: top;
			margin: 0 5px 0 0;
			padding: 8px;
			border-radius: 10px;
			.titre_menu {
				line-height: 30px;
			}
			&:hover {
				cursor: pointer;
			}
		}

		//fleche entre chaque menu
		.menu_fleche {
			display: inline-block;
			width: 15px;
			margin-top: 14px;
			&:after {
				content: "\f054";
				font-family: FontAwesome;
			}
		}

		//boutons a droite du menu
		.menu_boutons {
			float: right;
			margin-top: 5px;
		}

		//liste déroulante dans le menu
		.menu_liste {
			display: inline-block;
			vertical-align: top;
			margin-top: 10px;
		}
	}
}

//div avec un sous menu
.sous_menu {
	display: none;
	color: white;
	position: absolute;
	z-index: 20;
	font-size: 16px;

	//item du sous menu
	.sous_menu_item {
		padding: 10px 15px 10px 10px;
		div {
			display: inline-block;
			vertical-align: middle;
		}
		.sous_menu_icone {
			width: 25px;
			height: 25px;
			text-align: center;
		}
		&:hover {
			cursor: pointer;
		}
	}

	//item du sous menu disabled
	.sous_menu_item_disabled {
		.opacity(0.5);
		cursor: not-allowed!important;
	}
}

//bouton pour passer mode enseignant
#switch_mode {
	display: flex!important;
	justify-content: center;
	align-items: center;
}

/* --------------------------------------------
	CONTENU DU SITE
-------------------------------------------- */

#content {
	position: relative;
	max-width: @site-max-width;
	margin: 0 auto;
	overflow: visible;
	text-align: center;
	padding: 0 10px 0 10px;
}

/* --------------------------------------------
	FOOTER
-------------------------------------------- */

.footer {
	width: 100%;
	z-index: 20;
	height: 40px;
	color: white;
	box-shadow: 0px -2px 3px 0px @gris-border;

	//largeur du contenu
	.footer_content {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5em;
		position: relative;
		max-width: @site-max-width;
		margin: 0 auto;
		overflow: hidden;
		text-align: center;
		font-size:12px;
		padding-top: 10px;
		a {
			color: white!important;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}

/* --------------------------------------------
	CADRES D'AIDE 
--------------------------------------------- */

//content de l'aide
.cadre(@background, @border) {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0px 0px 5px 0px @border;
	line-height: 25px;
	background-color: @background;
	color: @font-color-disabled;
	padding: 10px;
	text-align: left;
	margin-bottom: @margin;
	table {
		width: 100%;
		td:first-child {
			width: 20px;
		}
	}

	//mise en forme du texte
	.texte_dans_cadre {
		margin-left: 5px;
		text-align: justify;
		div {
			margin-bottom: -3px;
		}
	}
}

//cadre bleu
.aides {
	.cadre(@bleu-background, @bleu-border);
	.texte_dans_cadre {
		span {
			color: @bleu;
			font-weight: bold;
		}
		.procedures {
			border-top: 1px dashed @bleu-border;
		}
	}
}

//cadre orange
.avertissement {
	.cadre(@orange-background, @orange-border);
}

//cadre rouge
.erreur {
	.cadre(@rouge-background, @rouge-border);
}

/* --------------------------------------------
	GRAPHIQUES
-------------------------------------------- */

#repere {
	td {
		padding:0!important;
	}
}

//div qui contient le repere
.repere {
	min-height: 65px;
	position: relative;

	//texte expliquant qu'il n'y a pas de niveau
	.no_boite {
		text-align: center;
		width: 100%;
		padding-top: 20px;
	}

	//div qui contient les 2 degradés
	.boite_degrade {
		position: relative;
		text-align: left;
		background-color: @gris-menu;
		height: 65px;
		width: 100%;

		//boite avec le texte du niveau
		.boite_niveau {
			position: absolute;
			font-weight: bold;
			z-index:10;
			top: 20px;
			left: 47%;
			span {
				background: white;
				border: 1px solid @gris-border;
				padding: 2px;
			}
		}

		//degrades des niveaux
		.degrade {
			display: inline-block;
			vertical-align: top;
			height: 65px;
			.opacity(0.5);
			z-index: 5;
			width: 50%;
		}

		//degrade vers la droite
		.degrade_droit_repere {
			.degrade;
			background: #f0f0f0; /*fallback for non-CSS3 browsers*/
			background: linear-gradient(to left, white, #686868);
		}

		//degrade vers la gauche
		.degrade_gauche_repere {
			.degrade;
			background: #f0f0f0; /*fallback for non-CSS3 browsers*/
			background: linear-gradient(to left, #686868, white);
		}

		//barres avec les écarts types de référence
		.et_ref {
			position: absolute;
			top: 0;
			height: 65px;
			width: 5px;
			background-color: grey;
		}

		.middle_repere {
			visibility: visible!important;
			background-color: #686868!important;
		}
	}
}

//boite avec le texte de la note standard
.note_standard {
	position: absolute;
	text-align: center;
	font-weight: bold;
	height: 50px;
	background: rgba(182, 182, 182, 0.6);
	div {
		background: white;
		border: 1px solid @gris-border;
		padding: 2px;
		display: inline-block;
		margin: 9px auto 0 auto;
		width: 80%;
		.opacity(1);
	}
	&:hover {
		div {
			border: 1px solid grey;
		}
	}
}

//barres des evaluations
.barre_eleve {
	width: 6px;
	height: 45px;
	margin-left: -4px;
	position: absolute;
	background-color: @font-color;
	.opacity(0.4);
	&:hover {
		.pointer;
		background-color: red!important;
	}
}

//nombre d'eleves au dessus des barres d'evaluations
.nb_eleves {
	position: absolute;
	z-index: 5;
	font-weight: bold;
	margin: 2px 0 0 -4px;
}

/* --------------------------------------------
	CHARTS
-------------------------------------------- */

//loading devant les charts
.loading_charts {
	position: absolute;
	top: 0px;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0;
	background: white url(../img/loading3.svg) no-repeat center center;
	z-index: 50;
	.opacity(0.7);
}

//carte de france
#canvas_france {
	margin-left: 20px;
	height: 500px;
}

//legende de la carte
#legende_carte {
	position: relative;
	width: 270px;
	margin-left: 30px;
	.carre_legende {
		display: inline-block;
		vertical-align: middle;
		width: 14px;
		height: 14px;
		border: 1px solid @font-color;
		margin: 5px;
		background-color: @bleu;
	}
	.texte_legende {
		display: inline-block;
		vertical-align: middle;
		width: 220px;
		margin: 5px;
		text-align: left;
	}
}

#etabs_etranger {
	display: flex;
	flex-wrap: wrap;
	div {
		flex: 1;
		flex-basis: 40%;
		margin-right: 30px;
	}
}

/* --------------------------------------------
	SPRITES DES ICONES
-------------------------------------------- */

//sprites image
.icones(@width, @height, @left, @top) {
	display: inline-block;
	background: url(../img/icones_sprites.png) no-repeat;
	width: @width;
	height: @height;
	background-position: @left @top;
}

//icone font awesome
.icone(@content, @color, @size) {
	display: inline-block;
	text-align: center;
	&:after {
		content: @content;
		font-family: FontAwesome;
		font-size: @size;
		color: @color;
		line-height: @size;
	}
}

//bouton
.icone-bouton {
	margin: 0 3px 0 0;
}

/* --------------------------------------------
	SIGNES ET SYMBOLES DIVERS
-------------------------------------------- */

//maison
.icone-maison {
	.icone("\f015", white, 30px);
	margin-top: -2px;
}
.maisonButton {
	.icone("\f015", @vert, 16px);
	.icone-bouton;
}

//plus vert
.icone-plus-petit {
	.icone("\f055", @vert, 16px);
}
.plusButton {
	.icone("\f055", @vert, 16px);
	.icone-bouton;
}

//gros plus blanc
.icone-plus-white {
	.icone("\f055", white, 28px);
}

//check vert
.icone-valide {
	.icone("\f00c", @vert, 16px);
	font-weight: bold;
}
.valideButton {
	.icone-valide;
	.icone-bouton;
}

//cercle barré
.icone-cancel {
	.icone("\f05e", @rouge, 16px);
	font-weight: bold;
}
.icone-cancel-disabled {
	.icone("\f05e", @font-color-disabled, 16px);
	font-weight: bold;
}
.cancelButton {
	.icone-cancel;
	.icone-bouton;
}
.icone-cancel-big {
	.icone("\f05e", @rouge, 28px);
	font-weight: bold;
	margin-top: 1px;
}

//croix seule
.icone-delete {
	.icone("\f00d", @rouge, 18px);
}
.icone-delete-disabled {
	.icone("\f00d", @font-color-disabled, 18px);
}
.deleteButton {
	.icone-delete;
	.icone-bouton;
}

//crayon
.icone-edit {
	.icone("\f040", @orange, 18px);
}
.icone-edit-disabled {
	.icone("\f040", @font-color-disabled, 18px);
}
.editButton {
	.icone-edit;
	.icone-bouton;
}

//poubelle
.icone-trash {
	.icone("\f1f8", @rouge, 18px);
}
.icone-trash-vert {
	.icone("\f1f8", @vert, 18px);
}
.icone-trash-disabled {
	.icone("\f1f8", @font-color-disabled, 18px);
}
.trashButton {
	.icone-trash;
	.icone-bouton;
}

//panneau attention
.icone-warning {
	.icone("\f071", @orange, 16px);
}
.warningButton {
	.icone-warning;
	.icone-bouton;
}
.icone-warning-gris {
	.icone("\f071", @font-color-disabled, 16px);
}

//i dans cercle
.icone-aides {
	.icone("\f05a", @bleu, 18px);
	.icone-bouton;
}

//fleche vers le bas
.icone-menu-deroulant {
	.icone("\f0d7", white, 18px);
	margin-left: 10px;
}

//check dans cercle
.icone-check {
	.icone("\f058", @font-color, 28px);
	margin: 1px 0 0 0;
}
.icone-check-white {
	.icone("\f058", white, 28px);
	margin: -2px 0 -3px 0;
}

//main retour
.icone-return {
	.icone("\f0a5", @font-color, 16px);
}
.returnButton {
	.icone-return;
	.icone-bouton;
}

//refresh
.icone-refresh {
	.icone("\f021", @font-color, 16px);
}
.refreshButton {
	.icone-refresh;
	.icone-bouton;
}

//main next
.icone-next {
	.icone("\f0a4", @font-color, 16px);
}
.nextButton {
	.icone-next;
	.icone-bouton;
}

//chaargement en cours
.icone-wait {
	width: 24px;
	height: 24px;
	background: url(../img/loading3.svg);
}
.waitButton {
	display: inline-block;
	background: url(../img/loading1.gif) no-repeat;
	height: 16px;
	width: 16px;
	margin: 0 3px -3px 0;
}

//speaker
.icone-speaker {
	.icone("\f028", @font-color, 18px);
	font-weight: normal!important;
	margin-right: 5px;
}

//speaker
.icone-speaker-petit {
	.icone("\f028", @font-color, 10pt);
	font-weight: normal!important;
	margin-left: 2px;
}

/* --------------------------------------------
	ICONES TOPBAR
-------------------------------------------- */

//logo facebook
.icone-facebook {
	.icones(24px,24px,0px,-142px);
}

//logo google plus
.icone-google {
	.icones(24px,24px,-27px,-142px);
}

//logo twitter
.icone-twitter {
	.icones(24px,24px,-55px,-142px);
}

//deconnexion
.icone-connect {
	.icones(24px,24px,-82px,-142px);
}

//livre
.icone-livre {
	.icone("\f02d", @font-color, 22px);
	margin: 0px 3px 0px 3px;
}

//videos
.icone-videos {
	.icone("\f03d", @font-color, 22px);
	margin: 0px 1px 0px 1px;
}

//roue crantée
.icone-tools {
	.icone("\f013", @font-color, 22px);
}
.icone-tools-petite {
	.icone("\f013", @font-color, 18px);
}
.icone-tools-big {
	.icone("\f013", @font-color, 26px);
}
.toolsButton {
	.icone("\f013", @font-color, 18px);
	.icone-bouton;
	margin: -2px 2px -1px 0;
}

//Aa dans cercle bleu
.icone-font {
	.icones(24px,24px,-110px,-142px);
}

//font size
.icone-fontsize {
	.icones(20px,20px,0px,-195px);
}
.fontsizeButton {
	.icone-fontsize;
	.icone-bouton;
}

//font family
.icone-fontfamily {
	.icones(20px,20px,-22px,-195px);
}
.fontfamilyButton {
	.icone-fontfamily;
	.icone-bouton;
}

//font interligne
.icone-linespacing {
	.icones(20px,20px,-44px,-195px);
}
.lineheightButton {
	.icone-linespacing;
	.icone-bouton;
}

//interlettres
.icone-letterspacing {
	.icones(20px,20px,-66px,-195px);
}
.letterspacingButton {
	.icone-letterspacing;
	.icone-bouton;
}

//intermots
.icone-wordspacing {
	.icones(20px,20px,-88px,-195px);
}
.wordspacingButton {
	.icone-wordspacing;
	.icone-bouton;
}

//document trois traits
.icone-enonce {
	.icones(16px,16px,-110px,-195px);
}

//nombre de mots
.icone-wordcount {
	.icones(18px,18px,-128px,-195px);
	margin: 1px -2px 0 -2px!important;
}
.wordcountButton {
	.icone-wordcount;
	.icone-bouton;
}

//types d'exos
.icone-types {
	.icones(18px,18px,-267px,-219px);
	margin: 1px -2px 0 -2px!important;
}
.typesButton {
	.icone-types;
	.icone-bouton;
	margin-bottom: -5px!important;
	margin-right: 3px!important;
}

/* --------------------------------------------
	ACTUALITES_GERER
-------------------------------------------- */

//journal sur fond rouge
.icone-journal {
	.icones(32px,32px,0px,-68px);
}

/* --------------------------------------------
	CALIBRATIONS_SEANCE
-------------------------------------------- */

//clé
.icone-key {
	.icone("\f084", @orange, 18px);
}

//clé
.icone-key-disabled {
	.icone("\f084", @font-color-disabled, 18px);
}

//panneau sur fond bleu
.icone-panneau {
	.icones(32px,32px,-36px,-68px);
}

//loupe sur fond vert
.icone-panneau-plus {
	.icones(32px,32px,-72px,-68px);
}

//lecture
.icone-play {
	.icone("\f04b", @font-color, 12px);
	margin-right: 5px;
}

//pause
.icone-pause {
	.icone("\f04c", @font-color, 12px);
	margin-right: 5px;
}

//div avec la progression des eleves
.progression {
	position: relative;
	margin: auto;
	height: 20px;
	border: 1px solid @gris-border;
	width: 95%;
	border-radius: 6px;
	.pointer;
	overflow: hidden;
	vertical-align: middle;
	.progression_barre {
		position: absolute;
		height: 20px;
		background-color: @gris-border;
		//border-radius: 6px;
	}
	.progression_texte {
		line-height: 20px;
		position: absolute;
		width: 95%;
		font-size: 12px;
		font-weight: bold;
	}
}

/* --------------------------------------------
	CLASSES_LISTE
-------------------------------------------- */

//classe sur fond gris
.icone-classes {
	.icones(32px,32px,-108px,-68px);
}

//eleve avec le plus
.icone-eleves {
	.icones(20px,20px,-148px,-195px);
}

//double élève
.icone-groupe {
	.icones(20px,20px,-193px,-170px);
}
.groupeButton {
	.icone-groupe;
	.icone-bouton;
}

//check vert
.icone-evaluations {
	.icones(20px,20px,-168px,-195px);
}

//cible
.icone-entrainements {
	.icones(20px,20px,-209px,-195px);
}

//2 eleves superposés
.icone-classe-inversee {
	.icones(20px,20px,-249px,-195px);
}
.icone-classe-inversee-disabled {
	.icones(20px,20px,-268px,-195px);
}

//icone changement de classe
.icone-changer-classe {
	.icones(20px,20px,-270px,-246px);
	margin-top: 5px;
}
.icone-changer-classe-disabled {
	.icones(20px,20px,-289px,-246px);
	margin-top: 5px;
}

//eleve avec le dossier
.icone-partage {
	.icones(18px,20px,-289px,-195px);
}

//eleve avec l'icone résultat
.icone-resultats {
	.icones(20px,20px,-287px,-219px);
}
.icone-resultats-disabled {
	.icones(19px,20px,-308px,-219px);
}

//flèche verte sur document
.icone-resultats2 {
	.icones(18px,18px,-309px,-194px);
}
.icone-resultats2-disabled {
	.icones(18px,18px,-327px,-194px);
}

//fleche vers le bas
.icone-import {
	.icone("\f019", @bleu, 16px);
}
.importButton {
	.icone-import;
	.icone-bouton;
}

//bouton reset compte formateur
.icone-reset-classe {
	.icone("\f021", @bleu, 18px);
}

//fleche qui pointe le bouton plus
#fleche_classes {
	position: absolute;
	margin: 5px 0 0 -45px;
	width: 30px;
}

/* --------------------------------------------
	COMMANDES_DEVIS
-------------------------------------------- */

//symbole euro
.icone-euro {
	.icone("\f153", white, 18px);
}

//document pdf
.icone-pdf {
	.icone("\f1c1", @rouge, 16px);
}
.icone-pdf-disabled {
	.icone("\f1c1", @font-color-disabled, 16px);
}
.pdfButton {
	.icone-pdf;
	.icone-bouton;
}

//fichier csv
.icone-csv {
	.icone("\f15c", @vert, 16px);
}

/* --------------------------------------------
	COMMANDES_EXPORTS
-------------------------------------------- */

//fleche vers le haut
.icone-export {
	.icone("\f093", white, 19px);
}
.exportButton {
	.icone("\f093", @bleu, 16px);
	.icone-bouton;
}

//deux documents
.icone-dupliquer {
	.icone("\f0c5", @orange, 16px);
}
.dupliquerButton {
	.icone-dupliquer;
	.icone-bouton;
}

/* --------------------------------------------
	COMMANDES_LISTE
-------------------------------------------- */

//liste
.icone-liste {
	.icone("\f0c9", white, 18px);
}

//payeurs
.icone-payeur {
	.icone("\f007", white, 18px);
}

//caisse sur fond orange
.icone-commandes {
	.icones(32px,32px,-145px,-68px);
}

//document
.icone-document {
	.icone("\f15c", @font-color, 16px);
}
.icone-document-white {
	.icone("\f15c", white, 16px);
}

//email
.icone-email {
	.icone("\f003", @bleu, 16px);
}

//sablier
.icone-sablier {
	.icone("\f254", @bleu, 16px);
}

//sablier
.icone-sablier-rouge {
	.icone("\f254", @rouge, 16px);
}

//cadenas
.icone-cadenas {
	.icone("\f023", @jaune, 16px);
}

//cadenas ouvert
.icone-cadenas-ouvert {
	.icone("\f09c", @jaune, 16px);
}

//empeche le redimensionement
.remarques_text {
	resize: none;
	max-height: 100px;
}

//titre du message aux users
#titre_message {
	width: 500px;
}

/* --------------------------------------------
	COMMANDES_RENOUVELLEMENTS
-------------------------------------------- */

//fleche avec temps
.icone-history {
	.icone("\f1da", @font-color, 18px);
}
.historyButton {
	.icone-history;
	.icone-bouton;
}
.icone-history-white {
	.icone("\f1da", white, 18px);
}

/* --------------------------------------------
	COMMANDES_RESUME
-------------------------------------------- */

//calculatrice
.icone-calculatrice {
	.icone("\f1ec", @font-color, 22px);
}
.icone-calculatrice-white {
	.icone("\f1ec", white, 18px);
}
.calculatriceButton {
	.icone("\f1ec", @font-color, 14px);
	.icone-bouton;
}

//jetons
.icone-jetons {
	.icone("\f1c0", @orange, 14px);
}
.jetonsButton {
	.icone-jetons;
	.icone-bouton;
}

/* --------------------------------------------
	COMPTES_LISTE
-------------------------------------------- */

//groupes sur fond jaune
.icone-comptes {
	.icones(32px,32px,-183px,-68px);
}

/* --------------------------------------------
	COMPTES_DETAILS
-------------------------------------------- */

//user sur fond jaune
.icone-user {
	.icones(32px,32px,-220px,-68px);
}

/* --------------------------------------------
	DOCUMENTS_MANUEL
-------------------------------------------- */

/* Folder tree indentation */
ul.folder-tree {
	list-style-type: none;
	padding-left: 20px;
}

.folder-tree ul {
	list-style-type: none;
	padding-left: 20px;
}

ul.folder-tree li {
	position: relative;
	.pointer;
	div {
		padding: 5px;
		&:hover {
			background-color: @gris-menu;
		}
	}
	ul {
		display: none;
	}
}

ul.folder-tree span.file {
	.icone-document;
	margin-right: 5px;
}

//pdf
.icone-pdf_white {
	.icones(24px,24px,-193px,-142px);
}
.icone-pdf_gris {
	.icones(24px,24px,-218px,-246px);
}

//download
.icone-download {
	.icone("\f019", @font-color, 28px);
	margin: -2px 0 -3px 0;
}

//dossier fermé
.icone-folder_close {
	.icone("\f07b", @jaune, 16px);
	margin-right: 2px;
	&:before {
		content: "\f054";
		font-family: FontAwesome;
		cursor: pointer;
		margin-right: 5px;
	}
}

//dossier ouvert
.icone-folder_open {
	.icone("\f07c", @jaune, 16px);
	margin-right: 2px;
	&:before {
		content: "\f078";
		font-family: FontAwesome;
		cursor: pointer;
		margin-right: 5px;
	}
}

.icone-folder_open_alone {
	.icone("\f07c", @jaune, 16px);
	margin-right: 2px;
}

//titre d'une page du manuel
#titre_page {
	font-size: 16px;
	font-weight: bold;
}

//lien pour revenir en haut
.lien_scroll_top {
	width: 100%;
	text-align: center;
	display: none;
	margin-top: 20px;
}

/* --------------------------------------------
	DOCUMENTS_VOIR
-------------------------------------------- */

//rectangle représentant un document
.document {
	width: 160px;
	border: 1px solid @gris-border;
	display: inline-block;
	margin: 10px;
	border-radius: 10px;
	overflow: hidden;
	&:hover {
		border: 1px solid grey;
		.pointer;
		img {
			.opacity(1);
		}
	}
	img {
		padding: 5px;
		.opacity(0.7);
	}
	.document_nom {
		margin-top: 5px;
		background-color: @gris-menu;
		padding: 5px;
		font-weight: bold;
		border-top: 1px dashed @gris-border;
	}
}

//titre de chaque partie
.titre_partie_documents {
	margin: auto;
	font-weight: bold;
	margin-bottom: 10px;
	padding: 10px;
	width: 90%;
	font-size: 18px;
	border-bottom: 3px double @gris-border;
}

/* --------------------------------------------
	ELEVE_ACCUEIL
-------------------------------------------- */

.cadre_fusion {
	display: inline-block;
	width: 200px;
	border: 1px dashed @gris-border;
	padding: 5px;
	border-radius: 10px;
	background-color: @gris-menu;
	vertical-align: middle;
}

/* --------------------------------------------
	ELEVE_CALIBRATION
-------------------------------------------- */

//div avec les commentaires
#comments {
	line-height: @line-height;
	text-align: left;
	margin-bottom: 10px;
}

/* --------------------------------------------
	ELEVE_EVALUATION
-------------------------------------------- */

//liste des items
.liste_items {
	background-color: white;
	border-bottom: 1px solid @gris-border;
	padding: 10px;
}

.selected_item {
	border-top: 1px dashed @gris-border;
	margin-top: 10px;
	display: none;
	line-height: 25px;
}

/* --------------------------------------------
	ELEVE_ENTRAINEMENT
-------------------------------------------- */

//barre d'avancement
.barre_avancement {
	width: 100%;
	margin-bottom: @margin;
	border-radius: 6px;
	text-align: left;
	background-color: white;
	.shadow;
	overflow: hidden;

	//avancement couleur
	#avancement_entrainement {
		height: 15px;
	}
}

//barre d'avancement calibration
.barre_avancement_calibration {
	width: 100%;
	border-radius: 6px;
	text-align: left;
	position: relative;
	overflow: hidden;
	margin-top: 5px;
	border: 1px solid @orange-border;

	.avancement_calibration {
		height: 22px;
		background-color: @orange-border;
	}
	.texte_avancement_calibration {
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		top: 50%;
		font-style: italic;
	}
}

//loader du contenu d'un item
#div_contenu_item_loading {
	position: absolute;
	left: 0;
	top:0;
	width: 100%;
	height: 100%;
	z-index: 50;
	background: white url(../img/loading3.svg) no-repeat center center;
}

.option_affichage {
	&:first-child {
		border-left: 1px dashed @gris-border;
		padding-left: 10px;
	}
	.titre_option {
		border-bottom: 3px double @gris-border;
		padding-bottom: 8px;
		margin-bottom: 10px;
	}
	display: inline-block;
	border-right: 1px dashed @gris-border;
	padding-right: 10px;
	margin-right: 10px;
}

/* --------------------------------------------
	ELEVES_IMPORT
-------------------------------------------- */

//sigle openoffice
.icone-openoffice {
	.icones(38px,38px,-258px,-68px);
}

//sigle excel
.icone-excel {
	.icones(38px,38px,-301px,-68px);
}

//numéros verts
.icone-1 {
	.icones(20px,20px,0px,-219px);
}
.icone-2 {
	.icones(20px,20px,-21px,-219px);
}
.icone-3 {
	.icones(20px,20px,-42px,-219px);
}
.icone-4 {
	.icones(20px,20px,-63px,-219px);
}
.icone-5 {
	.icones(20px,20px,-84px,-219px);
}
.icone-6 {
	.icones(20px,20px,-105px,-219px);
}
.icone-7 {
	.icones(20px,20px,-126px,-219px);
}

//bouton d'importation
.input_file_import {
	display: inline-block;
	position: relative;
	width: 220px;
	overflow: hidden;
	background-color: @gris-menu;
	border: 1px solid @gris-border;
	border-radius: 10px;
	color: @font-color;
	input {
		position: absolute;
		right: 0;
		top: 0;
		font-size: 600px;
		.opacity(0);
		text-align: right;
		.pointer;
	}
	&:hover {
		text-decoration: underline;
	}
	.input_file_import_txt {
		line-height: 25px;
		font-weight: bold;
		text-align: center;
	}
}

//erreur de la page d'importation des élèves
#erreur {
	margin-top: 10px;
	color: red;
}

//boutons excel oo
#bouton_excel, #bouton_oo {
	width: 200px;
	text-align: center;
	display: inline-block;
	.pointer;
}

/* --------------------------------------------
	ELEVES_LISTE
-------------------------------------------- */

//download blanc
.icone-download-white {
	.icone("\f019", white, 28px);
	margin: -2px 0 -3px 0;
}

//deux élèves sur fond jaune
.icone-liste_eleves {
	.icones(32px,32px,-324px,-142px);
}

//trait séparateur
.icone-separateur {
	display: inline-block;
	text-align: center;
	&:after {
		content: "|";
		color: @font-color;
		font-size: 25px;
	}
	margin-right: 10px;
	vertical-align: middle;
	height: 28px;
}

//fleche vers le blanc
.icone-export-white {
	.icone("\f093", white, 28px);
}

/* --------------------------------------------
	ELEVES_SEANCES
-------------------------------------------- */

//liste des séances d'un élève
.seance_eleve {
	text-align: left;
	padding: 5px 0 5px 0;
	border: 1px dashed @gris-border;
	border-radius: 10px;
	margin-top: 10px;
	.icone-seance {
		margin: 5px;
	}
	&:hover {
		background-color: @gris-menu;
		cursor: pointer;
	}
}

//seance terminee
.seance_terminee {
	border: 1px dashed @vert!important;
	background-color: @vert-background;
	&:hover {
		background-color: @vert-background!important;
		cursor: pointer;
	}
}

//indicateur de séance terminée
.icone_seance_terminee {
	.icone-valide;
	float: right;
	margin-right: 10px;
}

/* --------------------------------------------
	ENSEIGNANTS_LISTE
-------------------------------------------- */

//petit symbole classe
.icone-classe-petit {
	.icones(16px,16px,-149px,-219px);
}
.classeButton {
	.icone-classe-petit;
	.icone-bouton;
	margin-bottom: -3px;
}

/* --------------------------------------------
	ENTRAINEMENTS_SEANCE
-------------------------------------------- */

.ceinture_select {
	float: right;
	width: 40px;
	margin-top: 2px;
}

//div de fond des onglets
.fond_onglets {
	display: flex;
	position: relative;
	background-color: white;
	min-height: 38px;
	border-bottom: 1px solid @gris-border;
	padding: 10px 10px 0 10px;
	.barre_eleve {
		height: 39px;
	}
}

//onglet 
.onglet {
	.opacity(0.8);
	border-top: 1px solid @gris-border;
	border-right: 1px solid @gris-border;
	border-left: 1px solid @gris-border;
	float: left;
	margin-bottom: -1px;
	background-color: @background;
	padding: 10px 20px 10px 20px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	&:hover {
		.pointer;
	}
}

//onglet sélectionné
.onglet_selected {
	.onglet;
	.opacity(1);
	padding-top: 10px!important;
	background-color: white;
	font-weight: bold;
}

//onglet 
.onglet_niveau {
	.onglet;
	width: 10%;
	padding: 0!important;
}

//onglet sélectionné
.onglet_niveau_selected {
	.onglet_niveau;
	.opacity(1);
	background-color: white;
}

//rond d'évolution aux entrainements
.fond_niveau_rond {
	line-height:30px;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	text-align: center;
	margin: 5px auto 5px auto;
}

//fond des ceintures de compétences
.fond_niveau_ceinture {
	padding-top: 5px;
	background-color: @gris-menu;
	border-bottom: 1px dashed @gris-border;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.lettre_select_niveau {
	padding: 2px 5px 2px 5px;
	border-radius: 10px;
}

.indicateur_eval {
	border-left: 3px solid @font-color;
	padding-left: 5px;
}

.indicateur_vide {
	padding-left: 8px;
}

//couleur de chaque ceinture
.onglet_niveau_1 .fond_ceinture {
	fill: #FFF;
}

.onglet_niveau_2 .fond_ceinture {
	fill: #dc7be5;
}

.onglet_niveau_3 .fond_ceinture {
	fill: #ffea22;
}

.onglet_niveau_4 .fond_ceinture {
	fill: #fb9738;
}

.onglet_niveau_5 .fond_ceinture {
	fill: #49b952;
}

.onglet_niveau_6 .fond_ceinture {
	fill: #3c97d9;
}

.onglet_niveau_7 .fond_ceinture {
	fill: #8070b9;
}

.onglet_niveau_8 .fond_ceinture {
	fill: #a68a6e;
}

.onglet_niveau_9 .fond_ceinture {
	fill: black;
}

.onglet_niveau_10 .fond_ceinture {
	fill: #ff4e4e;
}

//couleurs de fonds
.onglet_niveau_gris {
	color: white!important;
	background-color: #b5b5b5!important;
}
.onglet_niveau_rouge {
	color: white!important;
	background-color: @rouge!important;
}
.onglet_niveau_orange {
	color: white!important;
	background-color: @orange!important;
}
.onglet_niveau_jaune {
	color: white!important;
	background-color: @jaune!important;
}
.onglet_niveau_vert {
	color: white!important;
	background-color: @vert!important;
}

//liste des items passes
.items_passes {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	text-align: left;
	max-height: 73px;
	overflow: auto;
	padding: 5px;
}

//cases des exercices
.case_item {
	position: relative;
	min-width: 15px;
	height: 30px;
	margin: 3px;
	display: inline-block;
	border-radius: 5px;
	overflow: hidden;
	border: 1px solid @gris-border;
	&:hover {
		.pointer;
		border: 1px solid @font-color;
	}
}
.case_item_selected {
	border: 1px solid @font-color!important;
}
.case_item_tutore {
	border: 1px solid red;
}
.case_item_tutore_selected {
	border-bottom: 5px solid @bleu!important;
}

.case_couleur {
	display: inline-block;
	width: 100%;
	height: 100%;
	text-align: center;
}
.case_item_rouge {
	background-color: @rouge;
	background: url(../img/hachures.png) repeat @rouge;
}
.case_item_gris {
	background-color: @gris-border;
}
.case_item_vert {
	background-color: @vert;
}
.case_indices {
	position: absolute;
	bottom: 0;
	background-color: @gris-menu;
	text-align: center;
	width: 15px;
	height: 15px;
	vertical-align: top;
	line-height: 16px;
	font-size: 8pt;
	color: @font-color-disabled;
}
.case_item_mots_bleus {
	color: @bleu!important;
}

//date entre les séries
.date_series {
	display: inline-block;
	border-radius: 5px;
	margin: 0 3px 0 3px;
	background-color: @gris-background;
	padding: 3px 6px 3px 6px;
}

//petit rond de séparation entre les séries
.separateur_series {
	display: inline-block;
	border-radius: 5px;
	margin: 3px;
	width: 17px;
	div {
		margin: auto;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: @gris-border;
	}
}

//boutons d'un entrainement tutoré
.boutons_tutore {
	background: white;
	border-bottom: 1px dashed @gris-border;
	padding: 3px 10px 4px 25px;
}

//case champ ouvert
.icone-champ-ouvert {
	.icone("\f0c9", @font-color, 15px);
}

//bouton item
.icone-item {
	.icones(16px,16px,-327px,-220px);
}

//bouton binomes
.icone-ordinateur {
	.icone("\f0e8", white, 28px);
	margin: -2px 0 -3px 0;
}

//graphique
.icone-graphique {
	.icones(24px,24px,-137px,-142px);
}

//graphique petit
.icone-graphique-petit {
	.icone("\f080", @font-color, 16px);
}
.graphiqueButton {
	.icone-graphique-petit;
	.icone-bouton;
}

//q noir avec check vert
.icone-question_ind {
	.icones(16px,16px,-169px,-220px);
}

//m bleu avec check vert
.icone-mots_bleus {
	.icones(16px,16px,-187px,-219px);
}

//fleche blanche haut
.icone-fleche-tuto-haut {
	.icones(50px,25px,0px,-104px);
}

//fleche pour répéter les dates
.icone-fleche-dates {
	.icone("\f0ab", @vert, 18px);
}

//fleche blanche bas
.icone-fleche-tuto-bas {
	.icones(50px,28px,-166px,-246px);
}

//exercice sur fond vert
.icone-exercice {
	.icones(32px,32px,-53px,-106px);
}

//eleves d'un groupe
.icone-eleves-groupe {
	.icone("\f0c0", @font-color, 18px);
}

//icone agrandir tbi
.icone-expand {
	.icone("\f065", @font-color, 26px);
}

//icone réduire tbi
.icone-retract {
	.icone("\f066", @font-color, 26px);
}

//petit point d'interrogation
.icone-astuce {
	.icone("\f059", @bleu, 16px);
}
.astuceButton {
	.icone-astuce;
	.icone-bouton;
}

//mot de passe
.mot_de_passe_classe {
	width: 330px;
	margin: auto;
	padding: 12px;
	font-size: 16px;
}

//tableau avec la liste des commandes
#nouvelle_serie_liste_eleves {
	overflow-y: scroll;
}

//div avec les params des entrainements
#dialog_parametres, #dialog_parametres_eleve {
	.dialog-message {
		display: grid;
		grid-template-columns: 1fr 2fr;
		padding: 0!important;
	}
	.parametres_menu_normal {
		color: @font-color-disabled;
		border-bottom: 1px solid @gris-border;
		text-align: center;
		padding: 10px;
		&:hover {
			.pointer;
			color: @font-color;
			background: @bleu-background;
		}
	}
	.parametres_menu_selected {
		color: @font-color;
		border-bottom: 1px solid @gris-border;
		background: @bleu-background;
		font-weight: bold;
		text-align: center;
		padding: 10px;
	}
	.parametres_contenus {
		min-height: 180px;
		border-left: 1px solid @gris-border;
		vertical-align: top;
		padding: 10px;
		line-height: 30px;
		overflow: hidden;
		text-align: left;
	}
}

//bouton en forme de switch
.switch {
	position: relative;
	width: 40px;
	height: 14px;
	background-color: @gris-border;
	cursor: pointer;
}

//couleur du label sélectionné
.label_selected {
	color: @bleu-form;
}

//labels d'un switch
.switch_label {
	.pointer;
}

//slider du switch
.slider {
	position: absolute;
	height: 20px;
	width: 20px;
	background-color: @bleu-form;
	left: 0;
	margin-top: -4px;
	border: 1px solid @bleu;
	border-radius: 3px;
}

//numéros des items
.num_items {
	.pointer;
	border-top: 0!important;
	&:hover {
		border-bottom: 3px solid @bleu!important;
		a {
			color: @bleu-hover;
		}
	}
}

//div avec les items
.div_items {
	margin: 0 1px 0 -1px;
	height: 50px;
	position: relative;
	.opacity(0.7);
	&:hover {
		.opacity(1);
	}
}

//div avec les items selected
.div_items_selected {
	.opacity(1);
}

//ajoute un masque de loading devant une div
.loading_div {
	background: white url(../img/loading3.svg) no-repeat center center;
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50;
}

//loading de l'item
#loading_item {
	.loading_div;
	height: 80%!important;
	margin-top: -20px;
}

//effectifs près des reponses aux items
.nb_reponses {
	font-weight: bold;
	.opacity(0.7);
	&:hover {
		text-decoration: underline;
		.pointer;
	}
}

//liste des reponses ouvertes
.reponse_ouverte {
	display: inline-block;
	margin-top: 5px;
	width: 100%;
}

//type d'inférence
.type_inference {
	text-align: center;
	border-bottom: 3px double @gris-border;
	padding-bottom: 20px;
	width: 98%;
	margin: 0 0 @margin 10px;
}

//enonce de l'item du bas
.enonce {
	text-align: justify;
	text-justify: inter-word;
	margin: 10px 0px @margin 0px;
}

//question de l'item du bas
.questions {
	width: 100%;
	text-align: justify;
	text-justify: inter-word;
	padding: 10px 0 5px 0;
	font-weight: bold;
}

//reponses de l'item du bas
.reponses {
	width: 100%;
	text-align: justify;
	text-justify: inter-word;
	margin: 10px 0 10px 0;
}

.legende_tutore {
	border-top: 1px dashed @gris-border;
	padding: 10px;
}

.item_marque {
	margin-top: -1px;
	&:after {
		content: "\f00c";
		font-family: FontAwesome;
		font-size: 10px;
		color: @vert;
	}
}

/* --------------------------------------------
	TCHAT
-------------------------------------------- */

//bulle trois petits points
.icone-discussion {
	.icone("\f27b", @bleu, 16px);
}
.discussionButton {
	.icone-discussion;
	.icone-bouton;
}

//icone de tchat a côté du nom de l'élève
.icone-tchat {
	.icone("\f27a", @font-color, 18px);
	position: relative;
}

//bulle avec le nombre de message non lus
.number_tchat {
	display: none;
	height: 10px;
	width: 10px;
	line-height: 10px;
	padding: 2px;
	position: absolute;
	margin: 1px 0 0 12px;
	color: white;
	font-weight: bold;
	font-size: 10px;
	background-color: @rouge-border;
	border-radius: 50%;
}

//div du tchat
#tchat_closed {
	display: none;
	position: fixed;
	bottom: 0;
	z-index: 50;
	width: 300px;
	padding: 15px;
	background: white;
	right: 10px;
	.shadow;
	text-align: center;
	font-weight: bold;
	.pointer;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

//div du tchat
#tchat {
	display: none;
	width: 60%;
	z-index: 50;
	background: white;
	position: fixed;
	bottom: 0;
	right: 10px;
	margin-left: 10px;
	.shadow;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	#eleves_tchat {
		height: 350px;
		max-height: 350px;
		overflow: auto;
	}
	.ligne_eleve_tchat {
		.eleve;
		&:hover {
			.pointer;
			background: @bleu-background;
		}
	}
	.ligne_eleve_tchat_selected {
		.pointer;
		background: @bleu-background;
	}
	#fermer_tchat {
		float: right;
		.icone-delete;
		.pointer;
		.opacity(0.8);
		width: 35px;
		&:hover {
			.opacity(0.6);
		}
	}
	#discussion_tchat {
		height: 350px;
		max-height: 350px;
		overflow: auto;
	}
	table {
		border-collapse: collapse;
		tr {
			td {
				padding: 5px;
				border-bottom: 1px solid @gris-border;
				&:first-child {
					border-right: 1px solid @gris-border;
				}
			}
			&:last-child > td {
				border-bottom: 0!important;
			}
		}
	}
}

//bulle tchat moi
.bulle_tchat_moi {
	position: relative;
	background: @bleu;
	width: 50%;
	right: 0px;
	color: white;
	text-align: justify;
	overflow-wrap: break-word;
	padding: 10px;
	margin: 0 5px 10px auto;
	border-radius: 10px;
	label {
		color: @font-color!important;
	}
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 10px;
		width: 0;
		height: 0;
		border: 15px solid transparent;
		border-top-color: @bleu;
		border-bottom: 0;
		border-right: 0;
		margin-bottom: -10px;
	}
}

//bulle tchat autre
.bulle_tchat_autre {
	position: relative;
	background: @gris-menu;
	width: 50%;
	left: 0px;
	text-align: justify;
	overflow-wrap: break-word;
	padding: 10px;
	margin: 0 auto 10px 5px;
	border-radius: 10px;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 10px;
		width: 0;
		height: 0;
		border: 15px solid transparent;
		border-top-color: @gris-menu;
		border-bottom: 0;
		border-left: 0;
		margin-bottom: -10px;
	}
}

//date du message
.date_tchat {
	padding-top: 5px;
	text-align: right;
	width: 100%;
	font-size: 12px;
}

//icone de suppression d'un message
.icone-delete-tchat {
	.icone("\f1f8", white, 14px);
}
.icone-delete-tchat-gris {
	.icone("\f1f8", @font-color, 14px);
}

/* --------------------------------------------
	EVALUATIONS_SEANCES
-------------------------------------------- */

/* The switch - the box around the slider */
.switch_seance {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 28px;
	margin-top: 2px;
}

/* Hide default HTML checkbox */
.switch_seance input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider_seance {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 34px;
}

.slider_seance_icone {
	display: flex;
	align-items: center;
	position: absolute;
	height: 20px;
	left: 4px;
	bottom: 3px;
	padding: 0 3px 2px 8px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 14px;
}

input:checked + .slider_seance {
	background-color: @vert;
}

input:checked + .slider_orange {
	background-color: @orange!important;
}

input:checked + .slider_seance .slider_seance_icone {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
}

//calibrations
.icone-calibrations {
	.icone("\f0c3", @font-color, 18px);
}
.icone-calibrations-big {
	.icones(24px,24px,-137px,-170px);
}
.icone-eleves-big {
	.icones(24px,24px,-165px,-170px);
}

//fleche a cote du bouton de lancement de la premiere evaluation
#fleche_evals {
	position: relative;
	margin: -22px 0 4px -15px;
	width: 30px;
}

//date de réponse sur la page des entrainements
.date_reponse {
	padding: 5px ;
	border: 1px dashed @vert;
	border-radius: 10px;
	font-style: italic;
	background: @vert-background;
	width: 45%;
	margin: auto;
	margin-bottom: 20px;
}

/* --------------------------------------------
	ENTRAINEMENTS_SEANCES
-------------------------------------------- */

.context_menu {
	position: absolute;
	background-color: white;
	padding: 5px;
	border: 1px solid @gris-border;
	line-height: 25px;
	div:hover {
		.pointer;
		background-color: @gris-menu;
	}
}

/* --------------------------------------------
	FORUM_DISCUSSIONS
-------------------------------------------- */

//bulle de forum
.icone-forum {
	.icones(32px,32px,-88px,-106px);
}

//discussion
.icone-discussions {
	.icones(32px,32px,-123px,-106px);
}

//post
.icone-post {
	.icones(32px,32px,-155px,-106px);
}

//postit
.icone-post-it {
	.icones(32px,32px,-185px,-106px);
}

/* --------------------------------------------
	FORUM_SUJET
-------------------------------------------- */

//tableau avec les posts d'un sujet du forum
#posts_table {
	margin-top: -1px;
	table {
		.forum_post {
			padding: 10px;
			text-align: justify;
			border-bottom: 1px solid @gris-border;
			line-height: @line-height;
		}
		.forum_posted_by {
			text-align: left;
			font-size: 11px;
			background-color: @gris-menu;
			padding-left: 6px;
			border-top: 1px solid @gris-border;
		}
	}
}

//titre des sujets
.liste_titres_forum {
	display: flex;
	gap: 5px;
}

//icone avant une discussion
.icone_discussions {
	.icone-discussions;
	float: left;
	margin: 4px 10px 0 5px!important;
}

//icone avant un sujet_post_it
.icone_post_it {
	.icone-post-it;
	float: left;
	margin: 4px 5px 0 5px!important;
}

//icone avant un sujet
.icone_post {
	.icone-post;
	float: left;
	margin: 4px 5px 0 5px!important;
}

//hover de la rubrique forum
.sujet_rubrique_forum:hover {
	text-decoration: underline!important;
}

//sujet resolu
.sujet_resolu {
	font-weight: bold;
	color: @vert;
}

//titre du sujet
.sujet_titre {
	max-width: 670px;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	vertical-align: bottom;
	.pointer;
	&:hover{
		text-decoration: underline;
	}
}

//date du sujet
.sujet_date {
	font-size: 11px;
}

//boutons edit_sujet
.boutons_edit_sujet {
	margin-left: auto;
	margin-top: 10px;
}

//style du numéro de page
.num_page(@border) {
	display: inline-block;
	text-align: center;
	width: 20px;
	height: 15px;
	border: 1px solid @border;
	margin-right: 2px;
}

//numero de la page en cours
.num_page_forum_selected {
	.num_page(@font-color);
	font-weight: bold;
}

//numero des pages non selectionnees
.num_page_forum {
	.num_page(@gris-border);
	.pointer;
	&:hover{
		border: 1px solid @font-color;
	}
}

//titre du sujet
#titre_sujet_modif, #titre_sujet {
	width: 495px;
	margin: 10px 0 10px 0;
}

/* --------------------------------------------
	GROUPES_CREER
-------------------------------------------- */

//liste des classes et des élèves
#classes_scroll, #eleves_scroll {
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 300px;
	.dndClasse {
		.pointer;
	}
	.eleve {
		.pointer;
		min-width: 150px;
		margin-left: 10px!important;
		&:hover {
			background-color: @bleu-background;
		}
	}
}

//espace après le nom de la classe
.dndClasse {
	margin-bottom: 5px;
}

//source dnd des classes
#groupe-classe_dndsource {
	text-align: left;
	min-height: 200px;
	#glisser_eleves {
		height: 125px;
		padding-top: 70px;
		text-align: center;
		overflow: hidden;
		border: 2px dashed @gris-border;
		font-size: 24px;
		.opacity(0.6);
	}
}

.filtre_eleves {
	padding: 5px 5px 10px 5px;
	border-bottom: 1px dashed @gris-border;
	margin-bottom: 5px;
}

//nom de l'élève
.liste_eleves {
	margin: 4px 0 0 4px;
	height: 29px;
}

//paramètres
.groupe_entrainement_params {
	padding: 10px!important;
	border-bottom: 3px double @gris-border;
}

//icone de suppression d'un élève lors de sa sélection dans le gpe
.delete_eleve_groupe {
	.icone-trash;
	margin: 1px 4px 0 5px;
}

//élève sélectionné dans un groupe
.eleve {
	text-align: left;
	display: inline-flex!important;
	flex-wrap: wrap;
	line-height: 25px;
	padding: 0 5px 0 5px;
	border: 1px dashed @gris-border;
	background-color: @orange-background;
	margin: 2px 4px 2px 0;
	//white-space: nowrap;
	border-radius: 6px;
	.icone-prise, .icone-prise-orange {
		border-right: 1px dashed @gris-border;
		padding-right: 5px;
		margin-right: 5px;
	}
	.icone-tchat {
		border-left: 1px dashed @gris-border;
		padding-left: 5px;
		margin-right: 0!important;
	}
	.icone-tools-petite {
		margin-right: 5px;
	}
}

//roue crantée
.icone-parametres {
	.icone("\f013", @font-color, 22px);
	//margin: -2px 0 -3px 0;
}

/* --------------------------------------------
	GROUPES_LISTE
-------------------------------------------- */

//fleche a cote du bouton de nouveau groupe
#fleche_groupes {
	vertical-align: middle;
}

//bouton GAR
.garButton {
	display: inline-block;
	background: url(../img/icone_gar.png) no-repeat;
	background-size: 16px 16px;
	height: 16px;
	width: 16px;
	margin: 0 3px -3px 0;
}

//tableau des modules
.tableau_modules {
	width: 100%;
	border-collapse: collapse;
	border: 1px solid @gris-border;
	td {
		padding: 5px;
	}
	.td_dashed {
		border-right: 1px dashed @gris-border;
		border-left: 0px;
	}
	td:first-child {
		border-right: 3px double @gris-border;
	}
}

//prise
.icone-prise {
	.icone("\f1e6", @vert, 14px);
}

//prise rouge
.icone-prise-orange {
	.icone("\f1e6", @orange, 14px);
}

//prise
.icone-prise-grise {
	.icone("\f1e6", @font-color, 14px);
}

//poubelle
.icone-poubelle {
	.icone("\f1f8", @font-color, 28px);
}
.icone-poubelle_hover{
	cursor: pointer;
	.icone("\f1f8", red, 28px);
}

//repartition
.icone-repartition {
	.icones(24px,24px,-166px,-142px);
}

//reinitialiser
.icone-shuffle {
	.icones(24px,24px,-447px,-41px);
}

//eleve
.icone-eleve {
	.icone("\f007", @font-color-disabled, 16px);
	margin-right: 2px;
}
.orthoButton {
	.icone("\f007", @font-color-disabled, 16px);
	.icone-bouton;
}

//lien binome
.icone-binome {
	.icone("\f0c1", @font-color-disabled, 16px);
}

//nb eleves dans le titre
.nb_eleves_dnd {
	margin-left: 10px!important;
}

//graphique avec les eleves
.graphique_derniere_eval, .graphique_eleve, .graphique_patient, .graphique_ordinateurs {
	position: relative;
	display: inline-block;
	margin-bottom: @margin;
	border-bottom: 1px solid @gris-border;
}

//border au dessus des niveaux
.graphique_niveaux {
	border-top: 3px double @gris-border;
}

.lettre_niveau {
	color: @gris-border;
}

//div élève sur la page des résultats complets
.div_eleves_resultats_complets {
	margin-bottom: @margin;
}

/* --------------------------------------------
	INSCRIPTION_ETABLISSEMENT
-------------------------------------------- */

#captchaImage {
	display: inline-block;
	vertical-align: top;
	margin-top: 3px;
}

#refresh_captcha {
	display: inline-block;
	border: 1px solid #528fba;
	padding: 5px 5px 6px 5px;
	margin: 3px 0 0 -1px;
	vertical-align: top;
}

//titre des parties
.titre_parties_form {
	.bleu;
	margin: auto;
	width: 100%;
	border-bottom: 1px dashed @gris-border;
	margin: 20px 0 20px 0;
}

/* --------------------------------------------
	INSCRIPTION_PAIEMENT
-------------------------------------------- */

.icone-cb {
	.icones(52px,32px,0px,-246px);
}
.icone-visa {
	.icones(52px,32px,-55px,-246px);
}
.icone-mastercard {
	.icones(52px,32px,-110px,-246px);
}

/* --------------------------------------------
	ITEMS_CREATION
-------------------------------------------- */

//revision
.icone-revision {
	.icone("\f079", white, 18px);
}

//exemple
.icone-exemple {
	.icone("\f059", @font-color, 28px);
}
.icone-exemple-white {
	.icone("\f059", white, 28px);
}

.mce-content-body {
	font-size: @font-size!important;
}

.mce-ico.mce-i-fa {
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* --------------------------------------------
	ITEMS_DUPLICATION
-------------------------------------------- */

//div avec le texte evolutif
.diff_dupliquer_item {
	text-align: justify;
	border: 1px dashed @gris-border;
	padding: 5px;
	margin: auto;
	margin-bottom: 5px;
	background-color: @orange-background;
}

/* --------------------------------------------
	ITEMS_LISTE
-------------------------------------------- */

//loupe
.icone-loupe-big {
	.icone("\f00e", @font-color, 28px);
}

/* --------------------------------------------
	ITEMS_REVISION
-------------------------------------------- */

//champs sur la page de révision
.champs_revision {
	display: block;
	text-align: justify;
	cursor: pointer;
	padding: 2px;
}

//image d'un item
.image_item {
	display: inline-block;
	width: 200px;
	height: 200px;
	padding: 2px;
	img {
		width: 200px;
	}
	.shadow;
}

//image page de résumé
.image_item_resume {
	display: inline-block;
	vertical-align: top;
	width: 100px;
	height: 100px;
	padding: 2px;
	margin: 0 8px 8px 0;
	img {
		width: 100px;
	}
	.shadow;
}

.champ_type {
	display: none;
	width: 100%;
}

//like
.icone-like {
	.icone("\f087", @font-color, 18px);
}
.icone-like-selected {
	.icone("\f087", @vert, 18px);
}

//dislike
.icone-dislike {
	.icone("\f088", @font-color, 18px);
}
.icone-dislike-selected {
	.icone("\f088", @rouge, 18px);
}

.rate {
	display: inline-block;
	height: 28px;
	vertical-align: middle;
}
.rate:not(:checked) > input {
	position: absolute;
	top: -9999px;
}
.rate:not(:checked) > label {
	float: right;
	width:0.8em;
	overflow: hidden;
	white-space: nowrap;
	cursor: pointer;
	font-size:25px;
	color: #ccc;
}
.rate:not(:checked) > label:before {
	content: '★ ';
}
.rate > input:checked ~ label {
	color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
	color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
	color: #c59b08;
}

/* --------------------------------------------
	ITEMS_RESUME
-------------------------------------------- */

//tableau de résumé des items
.tableau_resume_items {
	width: 100%;
	border-collapse: collapse;
	td {
		padding: 5px;
		border: 1px dashed @gris-border!important;
	}
}

//grand_type
.grand_type_resume {
	.pointer;
	border: 1px solid @gris-border;
	margin-bottom: 10px;
	border-radius: 10px;
	overflow: hidden;
	.div_bottom {
		text-align: justify;
	}
}

//contenu du grand type
.grand_type_resume_content {
	text-align: justify;
}

//tableau de procédures 
#regles_procedures {
	border-collapse: collapse;
	border: 1px solid @bleu-border;
	tr:first-child {
		height: 50px!important;
		text-align: center;
		border-bottom: 3px double @bleu-border!important;
	}
	td:first-child {
		padding: 10px;
	}
	td {
		text-align: center;
		border: 1px dashed @bleu-border!important;
	}
}

/* --------------------------------------------
	LICENCE_GERER
-------------------------------------------- */

//caddie sur fond gris
.icone-caddie {
	.icones(32px,32px,-218px,-106px);
}

//chorus
.icone-chorus {
	.icones(20px,20px,-246px,-246px);
}

/* --------------------------------------------
	ORTHO_DETAILS_SEANCE
-------------------------------------------- */

//loupe
.icone-loupe {
	.icone("\f00e", @bleu, 16px);
}

//bulle
.icone-bulle {
	.icone("\f27b", @bleu, 16px);
}


//tableau avec la liste des commandes
#liste_seances_patient {
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
}

/* --------------------------------------------
	ORTHO_EXERCICES
-------------------------------------------- */

//timer
.icone-timer {
	.icone("\f017", white, 28px);
}

//checked
.icone-checked {
	.icone("\f192", @font-color, 16px);
}
.checkedButton {
	.icone-checked;
	.icone-bouton;
}

//rejouer
.icone-rejouer {
	.icone("\f079", @font-color, 16px);
}

//a mise en forme
.icone-mise_en_forme {
	.icone("\f031", @font-color, 16px);
}

.timer_svg {
	width: 30px;
	#loader {
		fill: @font-color;
	}
}

#timer_temps {
	margin-left: 15px;
	vertical-align: top;
}

/* --------------------------------------------
	ORTHO_LISTE
-------------------------------------------- */

//afficher les patients
.icone-show-all {
	.icone("\f00b", @font-color, 18px);
}
.icone-show-all-vert {
	.icone("\f00b", @vert, 18px);
}
.icone-show-all-white {
	.icone("\f00b", white, 28px);
}

//div d'ajout d'un patient
#add_patient {
	border-bottom: 1px solid @gris-border;
}

#search_patient {
	padding: 10px;
}

/* --------------------------------------------
	PATIENTS_EXERCICES
-------------------------------------------- */

.div_partie_item {
	display: flex;
	justify-content: space-between;
}

.div_oeil {
	width: 100px;
	flex-shrink: 0;
	color: @bleu!important;
	.pointer;
	margin-left: auto;
	&:hover {
		text-decoration: underline;
	}
}

//oeil
.icone-oeil {
	.icone("\f06e", @bleu, 18px);
	text-align: right!important;
}

//oeil fermé
.icone-oeil-close {
	.icone("\f070", @bleu, 18px);
	text-align: right!important;
}

/* --------------------------------------------
	PRESENTATION_ACCUEIL
-------------------------------------------- */

//grid avec les cadres de connexion
.grid_acces {
	display: grid;
	grid-template-columns: repeat(2, minmax(270px, 1fr));
	column-gap: @margin;
	a {
		font-size: 16px;
		margin-left: 15px;
	}
}

//grid avec les cadres de présentation
.grid_presentation {
	display: grid;
	grid-template-columns: repeat(3, minmax(180px, 1fr));
	column-gap: @margin;
}

//grid avec les boutons
.grid_boutons {
	display: grid;
	grid-template-columns: repeat(4, minmax(135px, 1fr));
	column-gap: @margin;
	.div_bottom {
		padding: 0 0 10px 0;
	}
}

//si l'écran fait moins de 600px
@media (max-width: 600px){
	.grid_acces, .grid_presentation{
		grid-template-columns: 1fr;
	}
	.grid_boutons {
		grid-template-columns: repeat(2, minmax(100px, 1fr));
	}
	#tchat {
		width: 96%;
	}
	.eleve {
		max-width: 150px;
	}
}

@media (min-width: 1200px){
	#tchat {
		width: 50%;
	}
}

//enseignement
.icone-enseignement {
	.icones(64px,64px,-210px,0px);
}

//orthophonie
.icone-orthophonie {
	.icones(64px,64px,-280px,0px);
}

//malette marron
.icone-orthophoniste {
	.icones(32px,32px,-254px,-106px);
}

//ampoule fond rouge
.icone-issu_recherche {
	.icones(64px,64px,0px,0px);
}

//livres fond bleu
.icone-adaptatif {
	.icones(64px,64px,-68px,0px);
}

//graph fond vert
.icone-differencie {
	.icones(64px,64px,-137px,0px);
}

/* --------------------------------------------
	PRESENTATION_DIAPORAMA
-------------------------------------------- */

#carousel_background {
	opacity: 0.1;
	position: absolute;
	right: 20px;
	bottom: 60px;
	z-index: 1;
}

/* Next & previous buttons */
.prev, .next {
	cursor: pointer;
	padding: 0 10px 0 10px;
	font-weight: bold;
	font-size: 18px;
}

//slide
.step {
	position: relative;
	display: none;
	width: 100%;
	.fade;
}

.step_title {
	display: flex;
	gap: 10px;
	align-items: center;
	font-size: 20pt;
	margin: 10px 0 25px 0;
	b {
		width: 95%;
		text-align: center;
	}
}

//contenu du slide
.step_content {
	font-size: 16pt;
	line-height: 45px;
	text-align: justify;
	border-top: 2px dashed @gris-border;
	padding-top: 20px;
	text-align: center;
	.floated{
		float: left;
		width: 40%;
		padding: 10px 20px 0 0;
	}
}

//num du slide
.step_num {
	margin-left: auto;
	color: @font-color-disabled;
}

/* The dots/bullets/indicators */
.dot {
	cursor: pointer;
	height: 15px;
	width: 15px;
	margin: 0 2px;
	background-color: @gris-border;
	border-radius: 50%;
	display: inline-block;
	transition: background-color 0.6s ease;
}

.active_dot, .dot:hover {
	background-color: @font-color;
}

/* Fading animation */
.fade {
	animation-name: fade;
	animation-duration: 1.5s;
}

@keyframes fade {
	from {
		opacity: .4
	}
	to {
		opacity: 1
	}
}

/* --------------------------------------------
	PRESENTATION_ENSEIGNANT
-------------------------------------------- */

//photo fond orange
.icone-diaporama {
	.icones(32px,32px,-289px,-106px);
}

//planete fond bleu
.icone-carte {
	.icones(32px,32px,-324px,-106px);
}

//outils fond vert
.icone-serveur {
	.icones(32px,32px,-289px,-142px);
}

/* --------------------------------------------
	PRESENTATION_EQUIPE
-------------------------------------------- */

.photo_equipe {
	position: relative;
	height: 80px;
	width: 80px;
	margin: auto;
	div {
		position: absolute;
	}
}

/* --------------------------------------------
	RESULTATS_CLASSE
-------------------------------------------- */

//flèche retour
.icone-vue-normale {
	.icone("\f0e2", @font-color, 28px);
}

//page break lors de l'impression
.page_break {
	display: block;
	clear: both;
	page-break-after: always;
	margin-bottom: 30px;
}

//div avec les eleves
.graphique_resultats {
	position: relative;
	display: inline-block;
	border-top: 1px solid @gris-border;
	margin-bottom: @margin;
	table {
		font-size: @font-size;
	}
}

/* --------------------------------------------
	UTILISATEUR_ACCUEIL
-------------------------------------------- */

//grid avec les rubriques
.grid_rubriques {
	display: grid;
	grid-template-columns: repeat(2, minmax(270px, 1fr));
	column-gap: @margin;
	height: auto;
	align-items: flex-start;
}

//si l'écran fait moins de 600px
@media (max-width: 600px){
	.grid_rubriques {
		grid-template-columns: 1fr;
	}
}

//prise fond bleu
.icone-analytics {
	.icones(32px,32px,-218px,-142px);
}

//graph fond bleu foncé
.icone-statistiques {
	.icones(32px,32px,-254px,-142px);
}

//div qui contient une rubrique
.div_rubrique {
	&:hover {
		.pointer;
		.div_titre b {
			text-decoration: underline;
		}
	}
	#rubrique_forum_content {
		word-wrap: break-word;
		word-break: break-all;
	}
}

//loading d'une rubrique
.loading_rubrique {
	height: 93px;
	margin: 1px 0 0 1px;
	padding: 0;
	background: white url(../img/loading3.svg) no-repeat center center;
	position: absolute;
	z-index: 50;
	.opacity(0.7);
	width: 100%;
}

/* --------------------------------------------
	UTILISATEUR_PARAMETRES
-------------------------------------------- */

//securite du mot de passe
#sm {
	display: flex;
	margin-left: 3px;
	div {
		flex: 1;
		color: white;
		padding: 5px;
		border: 1px solid white;
	}
}

//case vide
.nrm {
	background-color: white;
}

//case rouge
.red {
	background-color: @rouge-border;
}

//case orange
.yellow {
	background-color: @orange;
}

//case verte
.green {
	background-color: @vert;
}

/* --------------------------------------------
	JS TABLESORTER
-------------------------------------------- */

.sortable{
	background-position: center right;
	background-repeat: no-repeat;
	cursor: pointer;
	white-space: normal;
	padding:4px 20px 4px 4px;
}

.sortable-default {
	background-image:url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
	.sortable;
}

.asc {
	background-image:url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
	.sortable;
	border-bottom: @font-color 2px solid!important;
}

.desc {
	background-image:url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
	.sortable;
	border-bottom: @font-color 2px solid!important;
}

/* --------------------------------------------
	SELECT2
-------------------------------------------- */

.select2-container {
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	position: relative;
	vertical-align: middle;
}
.select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 32px;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	display: block;
	padding-left: 8px;
	padding-right: 20px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
	background-color: transparent;
	border: none;
	font-size: 1em;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
}
.select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
	display: inline;
	list-style: none;
	padding: 0;
}
.select2-container .select2-selection--multiple .select2-selection__clear {
	background-color: transparent;
	border: none;
	font-size: 1em;
}
.select2-container .select2-search--inline .select2-search__field {
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    margin-left: 5px;
    padding: 0;
    max-width: 100%;
    resize: none;
    height: 18px;
    vertical-align: bottom;
    font-family: sans-serif;
    overflow: hidden;
    word-break: keep-all;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}

.select2-dropdown {
	background-color: white;
	border: 1px solid @gris-border;
	border-radius: 10px;
	box-sizing: border-box;
	display: block;
	position: absolute;
	left: -100000px;
	width: 100%;
	z-index: 1051;
}

.select2-results {
	display: block;
}

.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}

.select2-results__option {
	padding: 6px;
	user-select: none;
	-webkit-user-select: none;
}

.select2-results__option--selectable {
	cursor: pointer;
}

.select2-container--open .select2-dropdown {
	left: 0;
}

.select2-container--open .select2-dropdown--above {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-search--dropdown {
	display: block;
	padding: 4px;
}
.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
    display: none;
}

.select2-close-mask {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	background-color: #fff;
	filter: alpha(opacity=0);
}

.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid @gris-border;
	border-radius: 10px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 30px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    height: 26px;
    margin-right: 20px;
    padding-right: 0px;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #888 transparent transparent transparent;
	border-style: solid;
	border-width: 5px 4px 0 4px;
	height: 0;
	left: 50%;
	margin-left: -4px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
	float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
	left: 1px;
	right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
	background-color: #eee;
	cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #888 transparent;
	border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
	background-color: white;
	border: 1px solid @gris-border;
	border-radius: 10px;
	cursor: text;
	padding-bottom: 5px;
	padding-right: 5px;
	position: relative;
}
.select2-container--default .select2-selection--multiple.select2-selection--clearable {
    padding-right: 25px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    font-weight: bold;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    position: absolute;
    right: 0;
    padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid @gris-border;
    border-radius: 10px;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    padding: 0;
    padding-left: 20px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    cursor: default;
    padding-left: 2px;
    padding-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-right: 1px solid @gris-border;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #999;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    padding: 0 4px;
    position: absolute;
    left: 0;
    top: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
	background-color: #f1f1f1;
	color: #333;
	outline: none;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
	margin-left: 5px;
	margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
	padding-left: 5px;
	padding-right: 2px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
	border-left: 1px solid @gris-border;
	border-right: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
	float: left;
	margin-left: 10px;
	margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border: solid black 1px;
	outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
	background-color: #eee;
	cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
	display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid @gris-border;
}

.select2-container--default .select2-search--inline .select2-search__field {
	background: transparent;
	border: none;
	outline: 0;
	box-shadow: none;
	-webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
	padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -2em;
	padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -3em;
	padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -4em;
	padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
	margin-left: -5em;
	padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
	padding: 0;
}

.select2-container--default .select2-results__option--disabled {
	color: #999;
}

.select2-container--default .select2-results__option--selected {
	//background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
	//background-color: #5897fb;
	background-color: #ddd;
	//color: white;
}

.select2-container--default .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px;
}

.select2-container--classic .select2-selection--single {
	background-color: #f7f7f7;
	border: 1px solid @gris-border;
	border-radius: 10px;
	outline: 0;
	background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
	background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
	background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 30px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    height: 26px;
    margin-right: 20px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid @gris-border;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
    background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
	border-color: #888 transparent transparent transparent;
	border-style: solid;
	border-width: 5px 4px 0 4px;
	height: 0;
	left: 50%;
	margin-left: -4px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
	float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
	border: none;
	border-right: 1px solid @gris-border;
	border-radius: 0;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	left: 1px;
	right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
	border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: transparent;
    border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #888 transparent;
	border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
	background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
	background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
	background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
	background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
	background-color: white;
	border: 1px solid @gris-border;
	border-radius: 10px;
	cursor: text;
	outline: 0;
	padding-bottom: 5px;
	padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid @gris-border;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    padding: 0;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
    cursor: default;
    padding-left: 2px;
    padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #888;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    padding: 0 4px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
	color: #555;
	outline: none;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
	margin-left: 5px;
	margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
	padding-left: 5px;
	padding-right: 2px;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
	border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
	border: 1px solid @gris-border;
	outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
	outline: 0;
	box-shadow: none;
}

.select2-container--classic .select2-dropdown {
	background-color: white;
	border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
	border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
	border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
	max-height: 200px;
	overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
	padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
	color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
	background-color: #3875d7;
	color: white;
}

.select2-container--classic .select2-results__group {
	cursor: default;
	display: block;
	padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
	border-color: #5897fb;
}

/* --------------------------------------------
	SELECTIZE
-------------------------------------------- */

.selectize-control.plugin-drag_drop.multi>.selectize-input.dragging {
	overflow: visible;
}

.selectize-control.plugin-drag_drop.multi>.selectize-input>div.ui-sortable-placeholder {
	visibility: visible !important;
	background: #f2f2f2 !important;
	background: rgba(0, 0, 0, 0.06) !important;
	border: 0 none !important;
	box-shadow: inset 0 0 12px 4px #fff
}

.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
	content: "!";
	visibility: hidden
}

.selectize-control.plugin-drag_drop .ui-sortable-helper {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2)
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header {
	position: relative;
	padding: 10px 8px;
	border-bottom: 1px solid #d0d0d0;
	background: #f8f8f8;
	border-radius: 3px 3px 0 0
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close {
	position: absolute;
	right: 8px;
	top: 50%;
	color: #303030;
	opacity: .4;
	margin-top: -12px;
	line-height: 20px;
	font-size: 20px !important;
	text-decoration: none
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close:hover {
	color: black
}

.selectize-dropdown.plugin-optgroup_columns .selectize-dropdown-content {
	display: flex
}

.selectize-dropdown.plugin-optgroup_columns .optgroup {
	border-right: 1px solid #f2f2f2;
	border-top: 0 none;
	flex-grow: 1;
	flex-basis: 0;
	min-width: 0
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
	border-right: 0 none
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
	display: none
}

.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
	border-top: 0 none
}

.selectize-control.plugin-remove_button .item {
	display: inline-flex;
	align-items: center;
	padding-right: 0 !important
}

.selectize-control.plugin-remove_button .item .remove {
	color: inherit;
	text-decoration: none;
	vertical-align: middle;
	display: inline-block;
	padding: 2px 6px;
	border-left: 1px solid #d0d0d0;
	border-radius: 0 2px 2px 0;
	box-sizing: border-box;
	margin-left: 6px
}

.selectize-control.plugin-remove_button .item .remove:hover {
	background: rgba(0, 0, 0, 0.05)
}

.selectize-control.plugin-remove_button .item.active .remove {
	border-left-color: #cacaca
}

.selectize-control.plugin-remove_button .disabled .item .remove:hover {
	background: 0
}

.selectize-control.plugin-remove_button .disabled .item .remove {
	border-left-color: white
}

.selectize-control.plugin-clear_button .clear {
	text-decoration: none;
	display: flex;
	position: absolute;
	height: 100%;
	width: 25px;
	top: 0;
	right: calc(8px - 6px);
	color: #000;
	opacity: .4;
	font-weight: bold;
	border: 0;
	cursor: pointer;
	z-index: 1;
	font-size: 21px;
	justify-content: center;
	align-items: center
}

.selectize-control.plugin-clear_button .clear:hover {
	opacity: 1
}

.selectize-control.plugin-clear_button.single .clear {
	right: calc(8px - 6px + 1.5rem)
}

.selectize-dropdown.plugin-auto_position.selectize-position-top {
	border-top: 1px solid #d0d0d0;
	border-bottom: 0 none;
	border-radius: 3px 3px 0 0;
	box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.18)
}

.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active {
	border-radius: 0 0 3px 3px;
	border-top: 0 none
}

.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active::before {
	top: 0;
	bottom: unset
}

.selectize-control {
	position: relative;
	display: inline-block;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
	color: #303030;
	font-family: inherit;
	font-size: 13px;
	line-height: 18px;
	font-smoothing: inherit;
}

.selectize-input,
.selectize-control.single .selectize-input.input-active {
	background: #fff;
	cursor: text;
	display: inline-block
}

.selectize-input {
	border: 1px solid #d0d0d0;
	padding: 8px 8px;
	display: inline-block;
	width: 100%;
	position: relative;
	z-index: 1;
	box-sizing: border-box;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 3px
}

.selectize-control.multi .selectize-input.has-items {
	padding: calc(8px - 2px - 0px) 8px calc(8px - 2px - 3px - 0px)
}

.selectize-input.full {
	background-color: #fff
}

.selectize-input.disabled,
.selectize-input.disabled * {
	cursor: default !important
}

.selectize-input.focus {
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15)
}

.selectize-input.dropdown-active {
	border-radius: 3px 3px 0 0
}

.selectize-input>* {
	vertical-align: baseline;
	display: inline-block;
	transform: scale(1);
	transform-origin: 0 0;
}

.selectize-control.multi .selectize-input>div {
	cursor: pointer;
	margin: 0 3px 3px 0;
	padding: 2px 6px;
	background: #f2f2f2;
	color: #303030;
	border: 0 solid #d0d0d0
}

.selectize-control.multi .selectize-input>div.active {
	background: #e8e8e8;
	color: #303030;
	border: 0 solid #cacaca
}

.selectize-control.multi .selectize-input.disabled>div,
.selectize-control.multi .selectize-input.disabled>div.active {
	color: #7d7d7d;
	background: white;
	border: 0 solid white
}

.selectize-input>input {
	display: inline-block !important;
	padding: 0 !important;
	min-height: 0 !important;
	max-height: none !important;
	max-width: 100% !important;
	margin: 0 !important;
	text-indent: 0 !important;
	border: 0 none !important;
	background: none !important;
	line-height: inherit !important;
	user-select: auto !important;
	box-shadow: none !important
}

.selectize-input>input::-ms-clear {
	display: none
}

.selectize-input>input:focus {
	outline: none !important
}

.selectize-input>input[placeholder] {
	box-sizing: initial
}

.selectize-input.has-items>input {
	margin: 0 0 !important
}

.selectize-input::after {
	content: " ";
	display: block;
	clear: left
}

.selectize-input.dropdown-active::before {
	content: " ";
	display: block;
	position: absolute;
	background: #f0f0f0;
	height: 1px;
	bottom: 0;
	left: 0;
	right: 0
}

.selectize-dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 10;
	border: 1px solid #d0d0d0;
	background: #fff;
	margin: -1px 0 0 0;
	border-top: 0 none;
	box-sizing: border-box;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 0 0 3px 3px
}

.selectize-dropdown [data-selectable] {
	cursor: pointer;
	overflow: hidden
}

.selectize-dropdown [data-selectable] .highlight {
	background: rgba(125, 168, 208, 0.2);
	border-radius: 1px
}

.selectize-dropdown .option,
.selectize-dropdown .optgroup-header,
.selectize-dropdown .no-results,
.selectize-dropdown .create {
	padding: 5px 8px
}

.selectize-dropdown .option,
.selectize-dropdown [data-disabled],
.selectize-dropdown [data-disabled] [data-selectable].option {
	cursor: inherit;
	opacity: .5
}

.selectize-dropdown [data-selectable].option {
	opacity: 1;
	cursor: pointer
}

.selectize-dropdown .optgroup:first-child .optgroup-header {
	border-top: 0 none
}

.selectize-dropdown .optgroup-header {
	color: #303030;
	background: #fff;
	cursor: default
}

.selectize-dropdown .active {
	background-color: #e8e8e8;
	color: #303030
}

.selectize-dropdown .active.create {
	color: #495c68
}

.selectize-dropdown .selected {
	background-color: #e8e8e8;
	color: #303030
}

.selectize-dropdown .create {
	color: rgba(48, 48, 48, 0.5)
}

.selectize-dropdown .active:not(.selected) {
	background: #f5fafd;
	color: #495c68
}

.selectize-dropdown-content {
	overflow-y: auto;
	overflow-x: hidden;
	max-height: 200px;
	overflow-scrolling: touch;
}

.selectize-dropdown-emptyoptionlabel {
	text-align: center
}

.selectize-dropdown .spinner {
	display: inline-block;
	width: 30px;
	height: 30px;
	margin: 5px 8px
}

.selectize-dropdown .spinner:after {
	content: " ";
	display: block;
	width: 24px;
	height: 24px;
	margin: 3px;
	border-radius: 50%;
	border: 5px solid #d0d0d0;
	border-color: #d0d0d0 transparent #d0d0d0 transparent;
	animation: lds-dual-ring 1.2s linear infinite
}

@keyframes lds-dual-ring {
	0% {
		transform: rotate(0)
	}

	100% {
		transform: rotate(360deg)
	}
}

.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input input {
	cursor: pointer
}

.selectize-control.single .selectize-input.input-active,
.selectize-control.single .selectize-input.input-active input:not(:read-only) {
	cursor: text
}

.selectize-control.single .selectize-input:after {
	content: " ";
	display: block;
	position: absolute;
	top: 50%;
	right: 15px;
	margin-top: -3px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 5px 5px 0 5px;
	border-color: #808080 transparent transparent transparent
}

.selectize-control.single .selectize-input.dropdown-active:after {
	margin-top: -4px;
	border-width: 0 5px 5px 5px;
	border-color: transparent transparent #808080 transparent
}

.selectize-control.rtl {
	text-align: right
}

.selectize-control.rtl.single .selectize-input:after {
	left: 15px;
	right: auto
}

.selectize-control.rtl .selectize-input>input {
	margin: 0 4px 0 -2px !important
}

.selectize-control .selectize-input.disabled {
	opacity: .5;
	background-color: #fafafa
}

.selectize-dropdown.plugin-auto_position.selectize-position-top {
	border-top: 1px solid #d0d0d0;
	border-bottom: 0 none;
	border-radius: 3px 3px 0 0;
	box-shadow: 0 -6px 12px rgba(0, 0, 0, 0.18)
}

.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active {
	border-radius: 0 0 3px 3px;
	border-top: 0 none
}

.selectize-control.plugin-auto_position .selectize-input.selectize-position-top.dropdown-active::before {
	top: 0;
	bottom: unset
}

.selectize-control.plugin-clear_button .clear {
	text-decoration: none;
	display: flex;
	position: absolute;
	height: 100%;
	width: 25px;
	top: 0;
	right: calc(8px - 6px);
	color: #000;
	opacity: .4;
	font-weight: bold;
	border: 0;
	cursor: pointer;
	z-index: 1;
	font-size: 21px;
	justify-content: center;
	align-items: center
}

.selectize-control.plugin-clear_button .clear:hover {
	opacity: 1
}

.selectize-control.plugin-clear_button.single .clear {
	right: calc(8px - 6px + 1.5rem)
}

.selectize-control.plugin-drag_drop.multi>.selectize-input.dragging {
	overflow: visible
}

.selectize-control.plugin-drag_drop.multi>.selectize-input>div.ui-sortable-placeholder {
	visibility: visible !important;
	background: #f2f2f2 !important;
	background: rgba(0, 0, 0, 0.06) !important;
	border: 0 none !important;
	box-shadow: inset 0 0 12px 4px #fff
}

.selectize-control.plugin-drag_drop .ui-sortable-placeholder::after {
	content: "!";
	visibility: hidden
}

.selectize-control.plugin-drag_drop .ui-sortable-helper {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2)
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header {
	position: relative;
	padding: 10px 8px;
	border-bottom: 1px solid #d0d0d0;
	background: #f8f8f8;
	border-radius: 3px 3px 0 0
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close {
	position: absolute;
	right: 8px;
	top: 50%;
	color: #303030;
	opacity: .4;
	margin-top: -12px;
	line-height: 20px;
	font-size: 20px !important;
	text-decoration: none
}

.selectize-dropdown.plugin-dropdown_header .selectize-dropdown-header-close:hover {
	color: black
}

.selectize-dropdown.plugin-optgroup_columns .selectize-dropdown-content {
	display: flex
}

.selectize-dropdown.plugin-optgroup_columns .optgroup {
	border-right: 1px solid #f2f2f2;
	border-top: 0 none;
	flex-grow: 1;
	flex-basis: 0;
	min-width: 0
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
	border-right: 0 none
}

.selectize-dropdown.plugin-optgroup_columns .optgroup:before {
	display: none
}

.selectize-dropdown.plugin-optgroup_columns .optgroup-header {
	border-top: 0 none
}

.selectize-control.plugin-remove_button .item {
	display: inline-flex;
	align-items: center;
	padding-right: 0 !important
}

.selectize-control.plugin-remove_button .item .remove {
	color: inherit;
	text-decoration: none;
	vertical-align: middle;
	display: inline-block;
	padding: 2px 6px;
	border-left: 1px solid #d0d0d0;
	border-radius: 0 2px 2px 0;
	box-sizing: border-box;
	margin-left: 6px
}

.selectize-control.plugin-remove_button .item .remove:hover {
	background: rgba(0, 0, 0, 0.05)
}

.selectize-control.plugin-remove_button .item.active .remove {
	border-left-color: #cacaca
}

.selectize-control.plugin-remove_button .disabled .item .remove:hover {
	background: 0
}

.selectize-control.plugin-remove_button .disabled .item .remove {
	border-left-color: white
}